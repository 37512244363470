import React from "react";
import classNames from "classnames";
import { Container, Text, Button, Image } from "@atoms";
import { NextArrow } from "@molecules";
import t from "@utils/t";
import useLang from "@hooks/useLang";

const SectionIntro = ({
  label,
  largeLabelSpacing,
  heading,
  subheading,
  subheadingPosition,
  description,
  button,
  copy,
  hideIcon,
  image,
  descriptionStyle,
}) => {
  const lang = useLang();

  return (
    <section className="texture-pattern relative z-0 bg-viridian py-24 md:py-28">
      <Container frame>
        <div className="flex max-w-4xl flex-col gap-8 sm:gap-10 lg:gap-14">
          {label && (
            <Text
              variant="lg"
              className={classNames("font-bold uppercase text-white", {
                "lg:mb-54 mb-10 md:mb-20": largeLabelSpacing,
              })}
            >
              {label}
            </Text>
          )}

          <div className="mb-8">
            {subheading && subheadingPosition === "top" && (
              <Text variant="h5" className="text-white">
                {subheading}
              </Text>
            )}
            <Text variant="h1" className="text-white">
              {heading}
            </Text>
            {subheading && subheadingPosition === "bottom" && (
              <Text variant="h6" className="mt-4 text-white">
                {subheading}
              </Text>
            )}
          </div>

          {!hideIcon && <NextArrow />}

          {image && <Image image={image} caption={image?.caption} />}

          <div className="flex w-full flex-wrap items-end justify-between gap-8">
            <Text
              variant={descriptionStyle === "serif" ? "h3" : "h6"}
              className={classNames("text-white", {
                "max-w-xl": descriptionStyle !== "serif",
                "mt-8 md:mt-0": descriptionStyle !== "serif",
              })}
              richText={descriptionStyle !== "serif"}
            >
              {description}
            </Text>

            <Text
              variant="body"
              className={classNames("prose max-w-lg text-white", {})}
              richText
            >
              {copy}
            </Text>

            <Button
              onClick={() => {
                window.scrollBy({
                  top: window.innerHeight,
                  behavior: "smooth",
                });
              }}
              icon="smallArrow"
              color="black"
              size="sm"
            >
              <Text variant="button">{t("Learn more", lang)}</Text>
            </Button>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default SectionIntro;
